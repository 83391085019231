import * as React from "react";
import * as ReactDOM from "react-dom";
import { FocusStyleManager } from "@blueprintjs/core";
import Splash from "./Splash";
import Chonk from "./Chonk";

const App: React.FC = () => {
  FocusStyleManager.onlyShowFocusOnTabs();

  return (
    <div>
      <Splash />
      <Chonk />
    </div>
  );
};

ReactDOM.render(<App />, document.getElementById("main"));
