import * as React from "react";
import Globe from "./Globe";

const Chonk: React.FC<{}> = () => {
  return (
    <div className={"secondary-container"}>
      <div className={"secondary-container-globe"}>
        <Globe
          background={"#fafafa"}
          width={400}
          height={400}
          strokeWeight={5}
        />
      </div>
    </div>
  );
};

export default Chonk;
