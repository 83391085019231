import * as React from "react";
import Globe from "./Globe";
import Mailchimp from "./Mailchimp";

const Splash: React.FC = () => {
  const primaryTextStyle = "bp3-running-text bp3-text-large bp3-text-muted";
  const secondaryTextStyle = primaryTextStyle + " bp3-text-disabled";

  return (
    <div className={"primary-container"}>
      <div className={"primary-container-globe"}>
        <Globe
          background={"#ffffff"}
          width={350}
          height={350}
          strokeWeight={5}
        />
      </div>
      <div className={"primary-container-content"}>
        <section>
          <div className={"title"}>Halfway.</div>
          <div className={primaryTextStyle}>
            The most important organ in your body is the one that's allowing you
            to read and comprehend these very words. Its capacity knows no
            bounds.
            <p />
            You can use it to learn an instrument. Or to write poetry. Thousands
            have used it to understand the world of quantum mechanics and many
            more have used it to make art.
            <p />
            However you choose to use yours, here at Halfway, we believe you
            should look after it with the utmost of care.
            <p />
            <div className={secondaryTextStyle}>
              We're working on making therapy more accessible & affordable for
              everyone.
              <p />
              Would you like to hear about our progress?
              <p />
            </div>
          </div>
        </section>
        <Mailchimp />
      </div>
    </div>
  );
};

export default Splash;
