import * as React from "react";
import jsonp from "jsonp";
import { Intent, Spinner, Toaster, Position } from "@blueprintjs/core";

const AppToaster = Toaster.create({
  className: "toaster",
  position: Position.BOTTOM,
});

const Mailchimp: React.FC = () => {
  const [email, setEmail] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const emailRegex = RegExp(
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  );

  function handleSubscriptionTap() {
    if (emailRegex.test(email) == false) {
      AppToaster.show({
        icon: "error",
        message: "Please enter a valid email",
      });
      return;
    }

    setIsLoading(true);

    const fields = [
      {
        name: "EMAIL",
        placeholder: "Email",
        type: "email",
        required: true,
      },
    ];
    const fieldValues = {
      EMAIL: email,
    };
    const action =
      "https://halfway.us10.list-manage.com/subscribe/post?u=1e8e955eddf569783752ee9bd&amp;id=129888f204";

    const values = fields
      .map((field) => {
        return `${field.name}=${encodeURIComponent(fieldValues[field.name])}`;
      })
      .join("&");
    const path = `${action}&${values}`;
    const url = path.replace("/post?", "/post-json?");

    jsonp(url, { param: "c" }, (err, data) => {
      console.log(data);
      if (data.msg.includes("already subscribed")) {
        AppToaster.show({
          icon: "eye-open",
          message: "You've already subscribed with this email",
        });
      } else if (data.result !== "success") {
        AppToaster.show({
          icon: "error",
          message: "Something went wrong. Please try again in a minute.",
          intent: Intent.DANGER,
        });
      } else {
        AppToaster.show({
          message: "You are now subscribed!",
        });
      }
      setIsLoading(false);
      setEmail("");
    });
  }

  return (
    <div>
      <input
        type="email"
        value={email}
        name="EMAIL"
        className="subtle-input-field"
        placeholder="rehat@halfway.io"
        onChange={(event) => {
          setEmail(event.target.value);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleSubscriptionTap();
          }
        }}
        disabled={isLoading}
        required
      />
      {!isLoading ? (
        <button className={"primary-button"} onClick={handleSubscriptionTap}>
          Subscribe →
        </button>
      ) : (
        <button className={"primary-button"}>
          <Spinner size={16} />
        </button>
      )}
    </div>
  );
};

export default Mailchimp;
