import * as React from "react";
import Sketch from "react-p5";

interface IGlobeProps {
  background: string;
  width: number;
  height: number;
  strokeWeight: number;
}

const Globe: React.FC<IGlobeProps> = (props) => (
  <Sketch
    setup={(p5, canvasParentRef) => {
      p5.createCanvas(props.width, props.height, p5.WEBGL).parent(
        canvasParentRef
      );
      p5.colorMode(p5.HSB, 1);
      p5.strokeWeight(props.strokeWeight);
    }}
    draw={(p5) => {
      p5.rotateY(p5.millis() / 10000);
      p5.background(props.background);
      p5.rotateX(0.2);
      p5.rotateY(-p5.HALF_PI);
      p5.push();
      p5.sphere(p5.width * 0.42, 15, 8);
      p5.pop();
    }}
  />
);

export default Globe;
